import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { getStaffLogin } from ".";

const QUERY = gql`
  query Branches(
    $where: BranchWhereInput
    $orderBy: OrderByInput
    $skip: Int
    $limit: Int
  ) {
    branches(where: $where, orderBy: $orderBy, skip: $skip, limit: $limit) {
      data {
        id_branch
        branch_name
        public
        isDeleted
        closed
      }
    }
  }
`;

const formatOptionLabel = (res) => (
  <div
    style={{
      color: res?.public === 0 ? "red" : res?.closed === 1 ? "#FFB319" : "",
    }}
  >
    {res?.label}
  </div>
);

export default function SelectBranchesOrder({
  provinceId,
  onChange,
  className,
  disabled,
  value,
  isPublic,
  onFetchData,
  hidden,
  isCreate,
}) {
  const [items, setItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const user = getStaffLogin();
  const [fetchData, { data, loading }] = useLazyQuery(QUERY, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    fetchData({
      variables: {
        where: {
          owner_phone: String(user?.phone_number),
          isCache: true,
        },
      },
    });
  }, [user?.phone_number, searchParams, navigate]);

  useEffect(() => {
    const results = data?.branches?.data || [];
    if (onFetchData) {
      onFetchData(results);
    }
    if (results?.length > 0) {
      let filters = [];
      const _results = results?.map((item) => {
        let object = null;
        if (
          isCreate &&
          (Number(item?.id_branch) === 472 || Number(item?.id_branch) === 651)
        ) {
          object = null;
        } else {
          object = {
            ...item,
            value: item?.id_branch,
            label: item?.branch_name,
          };
        }

        return object;
      });
      if (isPublic) {
        filters = _results?.filter(
          (item) => item?.public === 1 && item?.isDeleted !== 1
        );
      } else {
        filters = _results;
      }
      setItems(filters);
    } else {
      setItems([]);
    }
  }, [data, isPublic, isCreate]);

  useEffect(() => {
    if (value) {
      const result = items?.filter(
        (item) => parseInt(item?.id_branch) === parseInt(value)
      );
      setSelectedOption(result[0] || null);
    } else {
      setSelectedOption(null);
    }
  }, [items, value]);

  return (
    <div style={{ minWidth: 200 }}>
      {!hidden ? (
        <Select
          className={className}
          isDisabled={disabled || items.length <= 1}
          value={selectedOption}
          placeholder={loading ? "ກຳລັງໂຫຼດ..." : "ເລືອກຊື່ສາຂາ"}
          onChange={(res) => {
            setSelectedOption(res);
            if (onChange) {
              onChange(res);
            }
          }}
          options={items}
          formatOptionLabel={formatOptionLabel}
        />
      ) : null}
    </div>
  );
}
