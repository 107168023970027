import moment from "moment";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { Confirm, Loading, Notify } from "notiflix";

// ປະກາດຕົວປ່ຽນ TOKEN ດືງຂໍ້ມູນຈາກ LocalStorage;
// export const TOKEN = localStorage.getItem("accessToken");
export const TOKEN_KEY = "accessToken";

// get parse JWT
export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  const object = JSON.parse(jsonPayload);
  return object;
};

//clear localStorage
export const clearLocalStorage = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const getStaffLogin = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    const staff = parseJwt(token);
    return staff;
  }
  return null;
};

export const getLocalProvince = () => {
  let local = localStorage.getItem("PROVINCE");
  if (local) {
    local = JSON.parse(local);
    return local;
  } else {
    const province = getStaffLogin()?.province;
    return province;
  }
};

//get local branch
export const getLocalBranch = () => {
  let local = localStorage.getItem("BRANCH");
  if (local) {
    local = JSON.parse(local);
    return local;
  } else {
    const branch = getStaffLogin()?.branch_id;
    return branch;
  }
};

export const currency = (value) => {
  if (!value) return 0;
  let currencys = new Intl.NumberFormat("en-CA").format(value);
  return currencys;
};

export const multipleItemStatusTransit = [
  "ORIGIN_BRANCH_RECEIVED_FORWARD",
  "TRANSIT_TO_DEST_BRANCH",
  "DEST_BRANCH_RECEIVED_FORWARD",
  "TRANSIT_TO_ORIGIN_BRANCH",
  "ORIGIN_BRANCH_RECEIVED_BACKWARD",
  "DEST_BRANCH_RECEIVED_BACKWARD",
];

//get field
export const getBranch = () => {
  let local = localStorage.getItem("BRANCH");
  if (local) {
    local = JSON.parse(local);
    return local;
  } else {
    const branch = getStaffLogin()?.branch_id;
    return branch;
  }
};

// Logout function

export const handleLogout = () => {
  localStorage.removeItem("accessToken");
  Navigate("/");
};

//function ແປງເລກໃຫ້ມີຈຸດ

export function formatNumberWithDotsAndDecimals(number) {
  const [integerPart] = number.toFixed(2).split(".");

  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `${formattedInteger}`;
}

export const formatDateGte = (date) => {
  const _date = date ? date : new Date();
  return moment(_date).format("YYYY-MM-DD");
};

export const formatDateLt = (date) => {
  const _date = date ? date : new Date();
  return moment(_date).add(1, "days").format("YYYY-MM-DD");
};

export const formatDateDash = (dashDate) => {
  let resp = moment(dashDate).format("YYYY-MM-DD");
  return resp;
};


export const messageSuccess = (e) => {
  // Notify.init({ position: "right-bottom" });
  Notify.success(e);
};

export const messageError = (e) => {
  Notify.failure(e);
};

export const messageWarning = (e) => {
  Notify.warning(e);
};

export const messageInfo = (e) => {
  Notify.info(e);
};

export const messageConfirm = (title, action) => {
  Confirm.show(
    "ເເຈ້ງເຕືອນ",
    title,
    "ຕົກລົງ",
    "ຍົກເລີກ",
    async function () {
      action();
    },
    function () {
      return false;
    }
  );
};


//select yeear
export function YearDropdown({ onChange }) {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const years = [];
  for (let i = 2023; i <= new Date().getFullYear(); i++) {
    years.push(i);
  }
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div style={{ fontSize: "14px", corsur: "pointer" }}>
      <label htmlFor="year-dropdown">ເລືອກປີ:</label>
      <select
        id="year-dropdown"
        value={selectedYear}
        onChange={handleYearChange}
        // style={{ borderRadius: '5px', fontSize: '14px',color:'black',padding:'5px 1.5rem' }}
        className="form-select"
        style={{ cursor: 'pointer' }}
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
}

//selectedMonth
export function MonthDropdown({onChange}) {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    onChange(e.target.value);
  };

  const months = Array.from({ length: 12 }, (_, i) => i + 1);

  return (
    <div style={{ fontSize: "14px" }}>
      <label htmlFor="month-dropdown">ເລືອກເດືອນ:</label>
      <select
        id="month-dropdown"
        value={selectedMonth}
        onChange={handleMonthChange}
        className="form-select"
        style={{ cursor: "pointer" }}
      >
        {months.map((month) => (
          <option key={month} value={month}>
            {month}
          </option>
        ))}
      </select>
    </div>
  );
}
