import React, { useState } from "react";
import { FaEyeSlash, FaRegUser } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { IoEyeSharp } from "react-icons/io5";
import { getStaffLogin } from "../../untills";

const USER_LOGIN = gql`
  mutation StaffLogin($where: StaffLoginInput!) {
    staffLogin(where: $where) {
      accessToken
    }
  }
`;

export function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const [customerLogin] = useMutation(USER_LOGIN);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await customerLogin({
        variables: {
          where: {
            phone_number: parseInt(username),
            password: password,
          },
        },
      });
      if (data?.staffLogin?.accessToken) {
        localStorage.setItem("accessToken", data.staffLogin.accessToken);

        const staff = getStaffLogin();
        if (
          staff.role !== "IT" &&
          staff.role !== "BRANCH_DIRECTOR" &&
          staff.role !== "STATE_DIRECTOR" &&
          staff.role !== "SUPER_ADMIN" &&
          staff.role !== "SUPER_STATE_DIRECTOR"
        ) {
          throw new Error("ບໍ່ສາມາດເຂົ້າສູ້ລະບົບໄດ້ ສິດຂອງທ່ານບໍ່ຖືກຕ້ອງ");
        }

        navigate("/CommissionMobile");
      } else {
        throw new Error("Login failed, accessToken is missing.");
      }
    } catch (err) {
      MySwal.fire({
        title: "ຂໍອາໄພ!",
        text:
          err.message === "ບໍ່ສາມາດເຂົ້າສູ້ລະບົບໄດ້ ສິດຂອງທ່ານບໍ່ຖືກຕ້ອງ"
            ? "ສິດຂອງທ່ານບໍ່ສາມາດເຂົ້າລະບົບນີ້!!!"
            : "ເບີໂທ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ!",
        icon: "error",
      });
    }
  };

  return (
    <>
      <div className="container-login">
        <div className="wrapper">
          <div className="login-box">
            <form onSubmit={handleSubmit}>
              <div className="input-box">
                <input
                  className="input-field"
                  type="text"
                  id="user"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
                <label className="label" htmlFor="user">
                  <span>
                    <FaRegUser />
                  </span>
                  Username
                </label>
              </div>

              <div className="input-box">
                <input
                  className="input-field"
                  id="pass"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <label className="label" htmlFor="pass">
                  <span>
                    <RiLockPasswordFill />
                  </span>{" "}
                  Password
                </label>
                <div
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="toggle-password"
                >
                  {showPassword ? <FaEyeSlash /> : <IoEyeSharp />}
                </div>
              </div>

              <div className="remember-forgot">
                <div className="forgot"></div>
              </div>

              <div className="input-box">
                <input
                  className="input-submit-login"
                  type="submit"
                  value="Login"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
