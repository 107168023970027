import React, { useEffect, useState } from "react";
import {
  currency,
  formatDateDash,
  getStaffLogin,
  messageConfirm,
  messageError,
  messageSuccess,
  MonthDropdown,
  YearDropdown,
} from "../../../untills";
import { Col, Row } from "react-bootstrap";
import { useLazyQuery, useMutation } from "@apollo/client";
import Schema from "../Schema";
import moment from "moment";

export default function Summary_Franchies({ branches, branchOrder }) {
  const [items, setItem] = useState([]);
  const currentYear = moment(new Date()).format("YYYY");
  const currentMonth = moment(new Date()).format("MM");
  const [BranchId, setBranchId] = useState("");
  const [getMonth, setGetMonth] = useState(currentMonth);
  const [getYear, setGetYear] = useState(currentYear);
  const [newLoad, setNewLoad] = useState(false);
  const User = getStaffLogin();

  const [updatePayment] = useMutation(Schema.UPDATE_COMMISSION);
  const [fetchData_Summary, { data }] = useLazyQuery(
    Schema.SUMMARY_COMMISSION,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    const isBranchDirector = User?.role === "BRANCH_DIRECTOR";
    const selectedBranch = isBranchDirector ? BranchId : branches;

    const NewWhere = {
      branch: branchOrder ? parseInt(branchOrder) : parseInt(selectedBranch),
      forMonth: parseInt(getMonth),
      forYear: parseInt(getYear),
    };
    fetchData_Summary({
      variables: {
        where: NewWhere,
        orderBy: "createdAt_DESC",
      },
    });
  }, [
    fetchData_Summary,
    BranchId,
    getMonth,
    getYear,
    branches,
    User?.role,
    branchOrder,
  ]);

  useEffect(() => {
    setItem(data?.franchiseCommissionPayments?.data || []);
  }, [data]);

  useEffect(() => {
    setBranchId(getStaffLogin()?.branch_id?.id_branch);
  }, []);

  const confirmCommission = (id) => {
    messageConfirm("ທ່ານຕ້ອງການຢືນຢັນ ແທ້ ຫຼື ບໍ່?", async () => {
      try {
        const _updatePayment = await updatePayment({
          variables: {
            data: {
              confirmStatus: 1,
              confirmBy: User?.first_name + User?.last_name,
              confirmedBy: User?.id_user,
            },
            where: { _id: parseInt(id) },
          },
        });

        if (_updatePayment) {
          messageSuccess("ການດຳເນີນງານສຳເລັດແລ້ວ");
          setNewLoad(!newLoad);
        } else {
          messageError("ການດຳເນິນງານບໍ່ສຳເລັດ");
        }
      } catch (error) {
        messageError("ການດຳເນິນງານບໍ່ສຳເລັດ");
      }
    });
  };

  return (
    <div>
      <>
        {User?.role !== "STATE_DIRECTOR" ? (
          <>
            <Row>
              <Col>
                <MonthDropdown
                  onChange={(selectedMonth) => setGetMonth(selectedMonth)}
                />
              </Col>
              <Col>
                <YearDropdown
                  onChange={(selectedYear) => setGetYear(selectedYear)}
                />
              </Col>
            </Row>
            {items?.map((value, index) => (
              <div key={index}>
                <hr />

                <div>
                  <Row className="pt-2 pb-2 small">
                    <div>ຊື່ບັນຊີ: {value?.accountName ?? " "} </div>
                    <div>ເລກບັນຊີ: {value?.accountNumber ?? " "}</div>
                    <div>
                      {" "}
                      ຄີເຄື່ອງ: {value?.percentOfCom || 0}% | ມອບເຄື່ອງ:{" "}
                      {value?.percentOfPickup || 0}%{" "}
                    </div>
                  </Row>
                  <Row className="Row-1">
                    <div className="space-between">
                      <div style={{ fontWeight: "bold" }}>ເຄື່ອງຄີໄດ້:</div>
                      <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                        {currency(value?.totalReceive ?? 0)} ອໍເດີ້
                      </div>
                    </div>
                    <div className="space-between">
                      <div style={{ fontWeight: "bold" }}>ມູນຄ່າທັງຫມົດ:</div>
                      <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                        <span>{currency(value?.finalReceive ?? 0)} </span> KIP
                      </div>
                    </div>
                  </Row>

                  <Row className="Row-1">
                    <div className="space-between">
                      <div style={{ fontWeight: "bold" }}>
                        ເຄື່ອງທີ່ສົ່ງສຳເລັດ:
                      </div>
                      <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                        <div>{currency(value?.totalSend ?? 0)} ອໍເດີ້</div>
                      </div>
                    </div>
                    <div className="space-between">
                      <div style={{ fontWeight: "bold" }}>ມູນຄ່າທັງຫມົດ:</div>
                      <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                        <span>{currency(value?.finalSend ?? 0)}</span> KIP
                      </div>
                    </div>
                  </Row>
                  <Row className="Row-1">
                    <Col className="p-0 font-weight-bold">
                      <div className="left">ເງິນ COD-KIP:</div>
                      <div className="left">ເງິນ COD-THB:</div>
                      <div className="left">ເງິນ COD-USD:</div>
                    </Col>

                    <Col className="p-0 font-weight-bold">
                      <div className="right " style={{ color: "black" }}>
                        {currency(value?.codValueKIP ?? 0)} KIP
                      </div>
                      <div className="right " style={{ color: "#DBA000" }}>
                        {currency(value?.codValueTHB ?? 0)} THB
                      </div>
                      <div className="right " style={{ color: "#A63EDE" }}>
                        <div>{currency(value?.codValueUSD ?? 0)} USD</div>
                      </div>
                    </Col>
                  </Row>

                  <Row className="Row-1">
                    <div className="head">ເງິນເພີ່ມ ແລະ ຫັກອື່ນອື່ນ</div>
                    <div className="space-between" style={{ color: "black" }}>
                      <span style={{ fontWeight: "bold" }}>ເງິນເພີ່ມ:</span>
                      <span style={{ fontWeight: "bold" }}>
                        {currency(value?.extraIncome ?? 0)} KIP
                      </span>
                    </div>
                    <div className="space-between" style={{ color: "red" }}>
                      <span style={{ fontWeight: "bold" }}>ເງິນຫັກ:</span>
                      <span style={{ fontWeight: "bold" }}>
                        {currency(value?.extraExpense ?? 0)} KIP
                      </span>
                    </div>
                  </Row>
                  <Row className="Row-1">
                    <div className="head">ລາຍຮັບສຸດທິ</div>
                    <div
                      className="space-between "
                      style={{ fontWeight: "bold" }}
                    >
                      <span>ຄ່າບໍລິການທັງຫມົດ:</span>
                      <span>{currency(value?.totalIncome ?? 0)} KIP</span>
                    </div>
                    <div
                      className="space-between "
                      style={{ fontWeight: "bold" }}
                    >
                      <span>ລາຍຮັບລວມອາກອນ:</span>
                      <span>{currency(value?.totalCommission ?? 0)} KIP</span>
                    </div>
                    <div
                      className="space-between "
                      style={{ color: "red", fontWeight: "bold" }}
                    >
                      <span>VAT 10%:</span>
                      <span>{currency(value?.taxIncome ?? 0)} KIP</span>
                    </div>
                    <div
                      className="space-between "
                      style={{ fontWeight: "bold" }}
                    >
                      <span>ຫຼັງອາກອນ:</span>
                      <span>{currency(value?.afterTaxIncome ?? 0)} KIP</span>
                    </div>
                    <div
                      className="space-between "
                      style={{ fontWeight: "bold" }}
                    >
                      <span>ເປີເຊັນ COD:</span>
                      <span> {currency(value?.codIncome ?? 0)} KIP</span>
                    </div>
                    <div
                      className="space-between"
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "green",
                      }}
                    >
                      <span>ເງິນສຸດທິ:</span>
                      <span>{currency(value?.finalIncome ?? 0)} KIP</span>
                    </div>
                  </Row>

                  <Row className="Row-1">
                    <div className="head">ຈັດການ</div>
                    <div
                      className="space-between"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <div>
                        <div>{value?.paymentID ?? " "}</div>
                        {value?.isCheck === 1 && value?.confirmStatus === 0 && (
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => {
                              confirmCommission(value?._id);
                            }}
                            disabled={value?.confirmStatus === 1}
                          >
                            <i className="icon-check me-2" />
                            ຢືນຢັນຖືກຕ້ອງ
                          </button>
                        )}
                      </div>

                      <div
                        style={{
                          fontSize: "12px",
                          width: "auto",
                          marginLeft: "2px",
                        }}
                      >
                        <div>ຢືນຍັນໂດຍ: {value?.confirmBy ?? ""}</div>
                        <div>
                          ວັນທີ່ຢືນຍັນ:{" "}
                          {value?.confirmedAt
                            ? formatDateDash(value?.confirmedAt)
                            : "--"}
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            ))}
          </>
        ) : null}
      </>
    </div>
  );
}
