import { IoLogOutOutline } from "react-icons/io5";
import Swal from "sweetalert2";


export default function Logout() {
    const _onLogout = async () => {
        const confirm_logout = await Swal.fire({
            text: "ທ່ານຕ້ອງການອອກຈາກລະບົບ ຫຼື ບໍ່!",
            confirmButtonText: "ຢືນຍັນ",
            cancelButtonText: "ຍົກເລີກ",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor:'#e11f28'

        });

        if (confirm_logout.isConfirmed) {
            localStorage.clear();
            sessionStorage.clear();

            setTimeout(() => {
                window.location.replace("/login");
            }, 1500);
        } else {
        }
    }
    return (
        <>
            <IoLogOutOutline style={{ width: '30px', height: '30px',cursor:'pointer',color:'#fff' }} onClick={_onLogout} />
        </>
    )
}

