import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "../page/login/Login";
import CommissionMobile from "../page/commissionmobile/Commission_Mobile";
import ProtectedRoute from "./ProtectedRoute";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />

      <Route
        path="/CommissionMobile"
        element={
          <ProtectedRoute>
            <CommissionMobile />
           </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
