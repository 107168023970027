import { gql, useLazyQuery } from "@apollo/client";
import _ from "lodash";
import { useEffect, useState } from "react";
import Select from "react-select";

const QUERY = gql`
  query Data($where: ProvinceWhereInput) {
    provinces(where: $where) {
      data {
        id_state
        provinceName
      }
    }
  }
`;

export default function SelectProvinceBtAdmin({
  className,
  style,
  onChange,
  disabled,
  value,
}) {
  const [items, setItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [fetchData, { data, loading }] = useLazyQuery(QUERY);
  useEffect(() => {
    fetchData({
      variables: {
        where: {},
      },
    });
  }, [fetchData]);

  useEffect(() => {
    const results = data?.provinces?.data || [];
    if (results?.length > 0) {
      const _results = results.map((item) => {
        const object = {
          ...item,
          value: item?.id_state,
          label: item?.provinceName,
        };
        return object;
      });
      setItems(_results);
    } else {
      setItems([]);
    }
  }, [data]);

  //set value
  useEffect(() => {
    if (value) {
      const result = items?.filter(
        (item) => parseInt(item?.id_state) === parseInt(value)
      );
      setSelectedOption(result[0] || null);
    } else {
      setSelectedOption(null);
    }
  }, [items, value]);

  return (
    <div style={{ minWidth: 200 }}>
      <Select
        styles={style}
        className={className}
        isDisabled={disabled}
        value={selectedOption}
        placeholder={loading ? "ກຳລັງໂຫຼດ..." : "ເລືອກຊື່ແຂວງ"}
        onChange={(res) => {
          setSelectedOption(res);
          if (onChange) {
            onChange(res);
          }
        }}
        options={items}
      />
    </div>
  );
}
