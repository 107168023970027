import { gql } from "@apollo/client";

export default class Schema {
  static Query_Commission = gql`
  query SummaryFranchiseItem($where: SummaryFranchiseItemWhereInput) {
      summaryFranchiseItem(where: $where) {
        totalReceiveItem
        totalReceiveAmount
        totalCompletedItem
        totalCompletedAmount
        totalCompletedItemChangeOnShop1
        totalCompletedAmountChangeOnShop1
        totalReceiveItemChangeOnShop1
        totalReceiveCompleted
        totalReceiveAmountChangeOnShop1
        totalReceiveAmountCompleted
        totalCodKIP
        totalCodTHB
        totalCodUSD
        totalCommissionReceive
        totalCommissionSend
        totalAfterTaxIncome
        totalBeforeTaxIncome
        totalTaxIncome
        finalCODCommission
        finalIncome
        percentOfCom
        percentOfPickup
        totalChangeOnShop0
        totalAmountChangeOnShop0
        totalReceiveNotSendAmount
        totalReceiveNotSend
      }
    }
  `;

  static QUERY_COMMISSION_PERFORMANCE = gql`
    query FranchiseCommissionPerformances(
      $where: FranchiseCommissionPerformanceWhereInput
    ) {
      franchiseCommissionPerformances(where: $where) {
        data {
          description
          incomeAmount
          expenseAmount
        }
      }
    }
  `;

  static QUERY_ITEMS_V2 = gql`
    query ItemsV2($where: ItemV2WhereInput, $noLimit: Boolean) {
      itemsV2(where: $where, noLimit: $noLimit) {
        data {
          packagePrice
          realItemValueKIP
          realItemValueTHB
          realItemValueUSD
        }
      }
    }
  `;
  static SUMMARY_COMMISSION = gql`
    query FranchiseCommissionPayments(
      $where: FranchiseCommissionPaymentWhereInput
      $orderBy: OrderByInput
    ) {
      franchiseCommissionPayments(where: $where, orderBy: $orderBy) {
        total
        data {
          _id
          paymentID
          isCheck
          finalIncome
          totalReceive
          finalReceive
          confirmStatus
          totalSend
          finalSend
          totalIncome
          totalCommission
          taxIncome
          codIncome
          accountNumber
          accountName
          afterTaxIncome
          branchName
          codValueKIP
          codValueTHB
          codValueUSD
          sentPrice
          percentOfCom
          percentOfPickup
          extraIncome
          extraExpense
          confirmBy
          confirmedAt
        }
      }
    }
  `;

  static UPDATE_COMMISSION = gql`
    mutation UpdateFranchiseCommissionPayment(
      $data: FranchiseCommissionPaymentInput!
      $where: FranchiseCommissionPaymentWhereInputOne
    ) {
      updateFranchiseCommissionPayment(data: $data, where: $where) {
        _id
      }
    }
  `;
}
