import React, { useEffect, useState } from "react";
import { FiGrid, FiX } from "react-icons/fi";
import {
  HiOutlineCalendar,
  HiOutlineTrendingUp,
  HiOutlineTrendingDown,
} from "react-icons/hi";
import { LiaBoxSolid } from "react-icons/lia";
import { BsBellFill } from "react-icons/bs";
import Avatar from "@mui/material/Avatar";
import Logout from "../login/Logout";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import {
  currency,
  formatDateGte,
  formatDateLt,
  getLocalBranch,
  getLocalProvince,
  getStaffLogin,
} from "../../untills";
import _ from "lodash";
import moment from "moment";
import { FaSpinner } from "react-icons/fa";
import Summary_Franchies from "./component/Summary_Franchies";
import SelectProvinceBtAdmin from "../../untills/SelectProvincesByAdmin";
import SelectBranchByAdmin from "../../untills/SelectBranchByAdmin";
import SelectBranchesOrder from "../../untills/selectBranchesOrder";

export function CommissionMobile() {
  const [percentageChange, setPercentageChange] = useState(0);
  const [percentageColor, setPercentageColor] = useState("black");
  const [IncomePerDay, setIncomePerDay] = useState(null);
  const [isActive, setIsActive] = useState("button1");
  const [BranchId, setBranchId] = useState("");
  const [profile, setProfile] = useState("");
  const [_performances, setIPerformances] = useState(null);
  const [_performances_before, setIPerformances_before] = useState(null);
  const [onClick, setonClick] = useState(false);
  const iconClick = () => setonClick(!onClick);
  const [totalSummary, setTotalSummary] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [province, setProvince] = useState("");
  const [branch, setBranch] = useState("");
  const [BranchesOrder, setBranchesOrder] = useState("");
  const user = getStaffLogin();

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };
  const today = startDate
    ? moment(startDate).subtract(1, "days").format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD");
  const yesterday = endDate
    ? moment(endDate).subtract(2, "days").format("YYYY-MM-DD")
    : moment().subtract(1, "days").format("YYYY-MM-DD");

  useEffect(() => {
    setBranchId(getStaffLogin()?.branch_id?.id_branch);
    setProfile(getStaffLogin()?.profile_picture);
  }, []);

  const [Query_Comission, { data, loading }] = useLazyQuery(
    Schema.Query_Commission,
    { fetchPolicy: "cache-and-network" }
  );

  const [
    Query_Commission_before,
    { data: query_Commission_before, loading: Commission_before_loading },
  ] = useLazyQuery(Schema.Query_Commission, {
    fetchPolicy: "cache-and-network",
  });

  const [
    Query_Comission_performance,
    { data: FranchisePerformances, loading: PerformancesLoading },
  ] = useLazyQuery(Schema.QUERY_COMMISSION_PERFORMANCE, {
    fetchPolicy: "cache-and-network",
  });

  const [
    Query_Comission_performance_before,
    { data: FranchisePerformances_before },
  ] = useLazyQuery(Schema.QUERY_COMMISSION_PERFORMANCE, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const isBranchDirector = user?.role === "BRANCH_DIRECTOR";
    const selectedBranch = isBranchDirector ? BranchId : branch?.id_branch;

    Query_Comission({
      variables: {
        where: {
          branch: BranchesOrder?.id_branch ? parseInt(BranchesOrder?.id_branch) : parseInt(selectedBranch), 
          startDate: formatDateGte(startDate),
          endDate: formatDateLt(endDate),
        },
      },
    });

    Query_Comission_performance({
      variables: {
        where: {
          branch: BranchesOrder?.id_branch ? parseInt(BranchesOrder?.id_branch) : parseInt(selectedBranch), 
          createdAtBetween: [formatDateGte(startDate), formatDateLt(endDate)],
        },
        limit: 5,
      },
    });
  }, [
    branch?.id_branch,
    Query_Comission,
    BranchId,
    Query_Comission_performance,
    startDate,
    endDate,
    user?.role,
    BranchesOrder
  ]);

  useEffect(() => {
    setProvince(getLocalProvince());
    setBranch(getLocalBranch());
    setBranchesOrder(getLocalBranch());
    if (data) {
      setTotalSummary(data?.summaryFranchiseItem);
    }
  }, [data]);

  useEffect(() => {
    const isBranchDirector = user?.role === "BRANCH_DIRECTOR";
    const selectedBranch = isBranchDirector ? BranchId : branch?.id_branch;

    Query_Commission_before({
      variables: {
        where: {
          branch: BranchesOrder?.id_branch ? parseInt(BranchesOrder?.id_branch) : parseInt(selectedBranch), 
          startDate: yesterday,
          endDate: today,
        },
      },
    });

    Query_Comission_performance_before({
      variables: {
        where: {
          branch: BranchesOrder?.id_branch ? parseInt(BranchesOrder?.id_branch) : parseInt(selectedBranch), 
          createdAtBetween: [yesterday, today],
        },
        limit: 5,
      },
    });
  }, [
    Query_Commission_before,
    Query_Comission_performance_before,
    yesterday,
    today,
    BranchId,
    branch?.id_branch,
    user?.role,
    BranchesOrder
  ]);

  useEffect(() => {
    setIncomePerDay(query_Commission_before?.summaryFranchiseItem);
  }, [query_Commission_before]);

  useEffect(() => {
    const resultsPerformances =
      FranchisePerformances?.franchiseCommissionPerformances?.data || [];
    setIPerformances(resultsPerformances);
  }, [FranchisePerformances]);

  const totalAddMoney = _.sumBy(_performances, "incomeAmount");
  const totalPayMoney = _.sumBy(_performances, "expenseAmount");

  // -----------------------------------------------------------------------------------------

  //ຄຳນວນລາຍໄດ້ເປັນເປີເຊັນ ຕໍ່ ມື້
  useEffect(() => {
    const resultsPerformances_before =
      FranchisePerformances_before?.franchiseCommissionPerformances?.data || [];
    setIPerformances_before(resultsPerformances_before);
  }, [FranchisePerformances_before]);

  const totalAddMoney_before = _.sumBy(_performances_before, "incomeAmount");
  const totalPayMoney_before = _.sumBy(_performances_before, "expenseAmount");

  useEffect(() => {
    if (totalSummary && IncomePerDay) {
      const currentIncome =
        Math.floor(totalSummary?.finalIncome || 0) +
        Number(totalAddMoney || 0) -
        Number(totalPayMoney || 0);

      const previousIncome =
        Math.floor(IncomePerDay?.finalIncome || 0) +
        Number(totalAddMoney_before || 0) -
        Number(totalPayMoney_before || 0);

      if (
        !isNaN(currentIncome) &&
        !isNaN(previousIncome) &&
        previousIncome !== 0
      ) {
        const percentChange =
          ((currentIncome - previousIncome) / previousIncome) * 100;
        setPercentageChange(percentChange.toFixed(2));
        setPercentageColor(percentChange > 0 ? "green" : "red");
      } else {
        setPercentageChange(0);
        setPercentageColor("black");
      }
    }
  }, [
    totalSummary,
    totalAddMoney,
    totalPayMoney,
    totalAddMoney_before,
    totalPayMoney_before,
    IncomePerDay,
  ]);

  return (
    <div>
      <div
        className="navbar pl-3 pr-3"
        style={{ borderBottom: "solid 2px #CCC8C8", overflow: "hidden" }}
      >
        <div className="space-between w-100">
          <div>
            <FiGrid className="icon-size-40" onClick={iconClick} />
          </div>
          <div>
            <HiOutlineCalendar
              className="icon-size-40"
              onClick={handleToggle}
            />
          </div>
        </div>

        <div className={isOpen ? "pickerdateActive" : "pickerdate"}>
          <div>
            <label>ວັນທີ່ເລີ່ມຕົ້ນ:</label>
            <br />
            <input
              className="inputDate"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div>
            <label>ວັນທີ່ສິ້ນສຸດ:</label>
            <br />
            <input
              className="inputDate"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>

        <div className={onClick ? "menu-mobile-active" : "menu-mobile"}>
          <div className="d-flex ">
            <div className="align-center pr-2">
              {profile ? (
                <>
                  <img src={profile} alt="pictures" className="profile" />
                </>
              ) : (
                <Avatar className="profile" />
              )}
            </div>
            <div className="align-center pr-2" style={{ color: "#fff" }}>
              {getStaffLogin()?.first_name + getStaffLogin()?.last_name}
            </div>
            <div className="align-center pr-2">
              <Logout />
            </div>
          </div>

          <div className="d-flex align-item-center justify-content-between">
            <div>
              <BsBellFill
                className="icon-size-25 mr-2"
                style={{ color: "#fff" }}
              />
            </div>

            <div onClick={iconClick}>
              <FiX className="icon-size-30" style={{ color: "#fff" }} />
            </div>
          </div>
        </div>
      </div>

      {user?.role !== "BRANCH_DIRECTOR" ? (
        <>
          <div className=" p-1">
            <SelectProvinceBtAdmin
              value={province?.id_state}
              onChange={(obj) => {
                if (obj?.id_state) {
                  setBranch({});
                  setProvince(obj);
                  localStorage.setItem("PROVINCE", JSON.stringify(obj));
                }
              }}
            />
          </div>
          <div className=" p-1">
            <SelectBranchByAdmin
              value={branch?.id_branch}
              provinceId={province?.id_state}
              onChange={(obj) => {
                if (obj?.id_branch) {
                  setBranch(obj);
                  localStorage.setItem("BRANCH", JSON.stringify(obj));
                  window.location.reload();
                }
              }}
            />
            <hr />
          </div>
        </>
      ) : null}

      {user?.role === "BRANCH_DIRECTOR" ? (
        <>
          <div className=" p-1">
            <SelectBranchesOrder
              value={branch?.id_branch}
              onChange={(obj) => {
                if (obj?.id_branch) {
                  setBranchesOrder(obj);
                  localStorage.setItem("BRANCH", JSON.stringify(obj));
                  window.location.reload();
                }
              }}
            />
            <hr />
          </div>
        </>
      ) : null}

      <div
        className="container-fluid pb-3"
        style={{ backgroundColor: "#f7f8f9" }}
      >
        {/* Row-1 */}
        <div className="monitor-box">
          <div className="space-between">
            <div style={{ color: "white" }}>
              {getStaffLogin()?.branch_id?.branch_name}
            </div>
            <div style={{ color: "white" }}>
              {getStaffLogin()?.first_name + getStaffLogin()?.last_name}
            </div>
          </div>

          {user?.role !== "STATE_DIRECTOR" ? (
            <>
              <hr />
              <div className="space-between" style={{ color: "white" }}>
                <div>ເງິນໄດ້ຮັບສຸດທິ:</div>
                <div>
                  ວັນທີ: {startDate ? startDate : formatDateGte(startDate)} ຫາ{" "}
                  {endDate ? endDate : formatDateGte(endDate)}
                </div>
              </div>

              <div className="space-between">
                <div className="font-bold-white-24">
                  {loading ? (
                    <FaSpinner
                      animation="border"
                      style={{ color: "#FFF", fontSize: "24px" }}
                    />
                  ) : (
                    currency(
                      Math.floor(totalSummary?.finalIncome) +
                        Number(totalAddMoney || 0) -
                        Number(totalPayMoney || 0)
                    )
                  )}{" "}
                  KIP
                </div>
                {profile ? (
                  <>
                    <img src={profile} alt="pictures" className="profile" />
                  </>
                ) : (
                  <Avatar className="profile" />
                )}
              </div>

              <div className="percent" style={{ color: percentageColor }}>
                {loading && Commission_before_loading && percentageChange ? (
                  <FaSpinner
                    animation="border"
                    style={{ color: "#FFF", fontSize: "16px" }}
                  />
                ) : (
                  <>
                    {percentageChange > 0 ? (
                      <>
                        <HiOutlineTrendingUp /> {percentageChange}% ເພິ່ມຂື້ນ
                      </>
                    ) : (
                      <>
                        <HiOutlineTrendingDown /> {percentageChange}% ຫຼຸດລົງ
                      </>
                    )}
                  </>
                )}
                ຈາກມື້ວານ
              </div>
            </>
          ) : null}
        </div>

        {/* Row-2 */}
        <div className="space-between mt-3">
          <button
            onClick={() => setIsActive("button1")}
            className={`${
              isActive === "button1"
                ? "button-switch-content-color"
                : "button-switch-content"
            }`}
          >
            ເຄື່ອງຄີເຂົ້າສາງ
          </button>

          <button
            onClick={() => setIsActive("button2")}
            className={`${
              isActive === "button2"
                ? "button-switch-content-color"
                : "button-switch-content"
            }`}
          >
            COD
          </button>
          <button
            onClick={() => setIsActive("button3")}
            className={`${
              isActive === "button3"
                ? "button-switch-content-color"
                : "button-switch-content"
            }`}
          >
            ສະຫຼຸບລາຍຮັບ
          </button>
        </div>

        {/* ro-3 */}
        <div className="container-content">
          {/* 1 */}
          <div
            className={`${
              isActive === "button1" ? "order-key-active" : "oder-key"
            }`}
          >
            <div className="content">
              <div className="container-icon">
                <LiaBoxSolid className="icon-content" />
              </div>

              <div className="head" style={{ flexGrow: "1" }}>
                ເຄື່ອງຄີເຂົ້າສາງ
                <br />
                <div style={{ fontSize: "12px", fontWeight: "normal" }}>
                  ຈຳນວນ{" "}
                  <span style={{ color: "#12D56A" }}>
                    {PerformancesLoading ? (
                      <FaSpinner
                        animation="border"
                        style={{ color: "#12D56A", fontSize: "18px" }}
                      />
                    ) : (
                      currency(totalSummary?.totalReceiveItem)
                    )}
                  </span>{" "}
                  ອັນ
                </div>
              </div>

              {user?.role !== "STATE_DIRECTOR" ? (
                <div>
                  <div className="value-green">
                    {PerformancesLoading ? (
                      <FaSpinner
                        animation="border"
                        style={{ color: "#12D56A", fontSize: "18px" }}
                      />
                    ) : (
                      currency(totalSummary?.totalReceiveAmount)
                    )}{" "}
                    ກີບ
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {/* 2 */}
          <div
            className={`${
              isActive === "button1" ? "order-key-active" : "oder-key"
            }`}
          >
            <div className="content">
              <div className="container-icon">
                <LiaBoxSolid className="icon-content" />
              </div>

              <div className="head" style={{ flexGrow: "1" }}>
                ເຄື່ອງທີ່ຄີຍັງບໍ່ສົ່ງສຳເລັດ
                <br />
                <div style={{ fontSize: "12px", fontWeight: "normal" }}>
                  ຈຳນວນ{" "}
                  <span style={{ color: "#e11f28" }}>
                    {PerformancesLoading ? (
                      <FaSpinner
                        animation="border"
                        style={{ color: "#e11f28", fontSize: "18px" }}
                      />
                    ) : (
                      currency(totalSummary?.totalReceiveNotSend)
                    )}
                  </span>{" "}
                  ອັນ
                </div>
              </div>

              {user?.role !== "STATE_DIRECTOR" ? (
                <div>
                  <div className="value-red">
                    <span style={{ color: "#e11f28" }}>
                      {PerformancesLoading ? (
                        <FaSpinner
                          animation="border"
                          style={{ color: "#e11f28", fontSize: "18px" }}
                        />
                      ) : (
                        currency(totalSummary?.totalReceiveNotSendAmount)
                      )}{" "}
                      ກີບ
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {/* 3 */}
          <div
            className={`${
              isActive === "button1" ? "order-key-active" : "oder-key"
            }`}
          >
            <div className="content">
              <div className="container-icon">
                <LiaBoxSolid className="icon-content" />
              </div>
              <div className="head" style={{ flexGrow: "1" }}>
                ຈ່າຍຕົ້ນທາງສົ່ງສຳເລັດ
                <br />
                <div style={{ fontSize: "12px", fontWeight: "normal" }}>
                  ຈຳນວນ{" "}
                  <span style={{ color: "#12D56A" }}>
                    {PerformancesLoading ? (
                      <FaSpinner
                        animation="border"
                        style={{ color: "#12D56A", fontSize: "18px" }}
                      />
                    ) : (
                      currency(totalSummary?.totalCompletedItemChangeOnShop1)
                    )}
                  </span>{" "}
                  ອັນ
                </div>
              </div>

              {user?.role !== "STATE_DIRECTOR" ? (
                <div>
                  <div className="value-green">
                    {PerformancesLoading ? (
                      <FaSpinner
                        animation="border"
                        style={{ color: "#12D56A", fontSize: "18px" }}
                      />
                    ) : (
                      currency(totalSummary?.totalCompletedAmountChangeOnShop1)
                    )}{" "}
                    ກີບ
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {/* 4 */}
          <div
            className={`${
              isActive === "button1" ? "order-key-active" : "oder-key"
            }`}
          >
            <div className="content">
              <div className="container-icon">
                <LiaBoxSolid className="icon-content" />
              </div>
              <div className="head" style={{ flexGrow: "1" }}>
                ຈ່າຍຕົ້ນທາງຍັງບໍ່ສົ່ງສຳເລັດ
                <br />
                <div style={{ fontSize: "12px", fontWeight: "normal" }}>
                  ຈຳນວນ{" "}
                  <span style={{ color: "#e11f28" }}>
                    {PerformancesLoading ? (
                      <FaSpinner
                        animation="border"
                        style={{ color: "#e11f28", fontSize: "18px" }}
                      />
                    ) : (
                      currency(totalSummary?.totalReceiveItemChangeOnShop1)
                    )}
                  </span>{" "}
                  ອັນ
                </div>
              </div>

              {user?.role !== "STATE_DIRECTOR" ? (
                <div>
                  <div className="value-red">
                    <span style={{ color: "#e11f28" }}>
                      {PerformancesLoading ? (
                        <FaSpinner
                          animation="border"
                          style={{ color: "#e11f28", fontSize: "18px" }}
                        />
                      ) : (
                        currency(totalSummary?.totalReceiveAmountChangeOnShop1)
                      )}{" "}
                      ກີບ
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {/* 5 */}
          <div
            className={`${
              isActive === "button1" ? "order-key-active" : "oder-key"
            }`}
          >
            <div className="content">
              <div className="container-icon">
                <LiaBoxSolid className="icon-content" />
              </div>
              <div className="head" style={{ flexGrow: "1" }}>
                ຈ່າຍປາຍທາງສົ່ງສຳເລັດ
                <br />
                <div style={{ fontSize: "12px", fontWeight: "normal" }}>
                  ຈຳນວນ{" "}
                  <span style={{ color: "#12D56A" }}>
                    {PerformancesLoading ? (
                      <FaSpinner
                        animation="border"
                        style={{ color: "#12D56A", fontSize: "18px" }}
                      />
                    ) : (
                      currency(totalSummary?.totalReceiveCompleted)
                    )}
                  </span>{" "}
                  ອັນ
                </div>
              </div>

              {user?.role !== "STATE_DIRECTOR" ? (
                <div>
                  <div className="value-green">
                    {PerformancesLoading ? (
                      <FaSpinner
                        animation="border"
                        style={{ color: "#12D56A", fontSize: "18px" }}
                      />
                    ) : (
                      currency(totalSummary?.totalReceiveAmountCompleted)
                    )}{" "}
                    ກີບ
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {/* 6 */}
          <div
            className={`${
              isActive === "button1" ? "order-key-active" : "oder-key"
            }`}
          >
            <div className="content">
              <div className="container-icon">
                <LiaBoxSolid className="icon-content" />
              </div>
              <div className="head" style={{ flexGrow: "1" }}>
                ຈ່າຍປາຍທາງຍັງບໍ່ສົ່ງສຳເລັດ
                <br />
                <div style={{ fontSize: "12px", fontWeight: "normal" }}>
                  ຈຳນວນ{" "}
                  <span style={{ color: "#e11f28" }}>
                    {PerformancesLoading ? (
                      <FaSpinner
                        animation="border"
                        style={{ color: "#e11f28", fontSize: "18px" }}
                      />
                    ) : (
                      currency(totalSummary?.totalChangeOnShop0)
                    )}
                  </span>{" "}
                  ອັນ
                </div>
              </div>

              {user?.role !== "STATE_DIRECTOR" ? (
                <div>
                  <div className="value-red">
                    <span style={{ color: "#e11f28" }}>
                      {PerformancesLoading ? (
                        <FaSpinner
                          animation="border"
                          style={{ color: "#e11f28", fontSize: "18px" }}
                        />
                      ) : (
                        currency(totalSummary?.totalAmountChangeOnShop0)
                      )}{" "}
                      ກີບ
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {/* 7 */}
          <div
            className={`${
              isActive === "button1" ? "order-key-active" : "oder-key"
            }`}
          >
            <div className="content">
              <div className="container-icon">
                <LiaBoxSolid className="icon-content" />
              </div>
              <div className="head" style={{ flexGrow: "1" }}>
                ສົ່ງສຳເລັດ
                <br />
                <div style={{ fontSize: "12px", fontWeight: "normal" }}>
                  ຈຳນວນ{" "}
                  <span style={{ color: "#12D56A" }}>
                    {PerformancesLoading ? (
                      <FaSpinner
                        animation="border"
                        style={{ color: "#12D56A", fontSize: "18px" }}
                      />
                    ) : (
                      currency(totalSummary?.totalCompletedItem)
                    )}
                  </span>{" "}
                  ອັນ
                </div>
              </div>
              {user?.role !== "STATE_DIRECTOR" ? (
                <div>
                  <div className="value-green">
                    {PerformancesLoading ? (
                      <FaSpinner
                        animation="border"
                        style={{ color: "#12D56A", fontSize: "18px" }}
                      />
                    ) : (
                      currency(totalSummary?.totalCompletedAmount)
                    )}{" "}
                    ກີບ
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {/* ສະຫຼຸບລາຍຮັບ revenue */}

          <div
            className={`${
              isActive === "button3" ? "Revenue-active" : "Revenue"
            } `}
          >
            <Summary_Franchies branches={branch?.id_branch} branchOrder={branch?.id_branch} />
          </div>

          {/* COD */}
          {user?.role !== "STATE_DIRECTOR" ? (
            <div className={`${isActive === "button2" ? "COD-active" : "COD"}`}>
              <div style={{ borderBottom: "2px solid #CCC8C8" }}>
                <div style={{ padding: "5px 0" }}>
                  ເງິນ COD-KIP:{" "}
                  <span style={{ color: "#12D56A" }}>
                    {currency(totalSummary?.totalCodKIP)}
                  </span>{" "}
                  KIP
                </div>
                <div style={{ padding: "5px 0" }}>
                  ເງິນ COD-THB:{" "}
                  <span style={{ color: "#DBA000" }}>
                    {currency(totalSummary?.totalCodTHB)}
                  </span>{" "}
                  THB
                </div>
                <div style={{ padding: "5px 0" }}>
                  ເງິນ COD-USD:{" "}
                  <span style={{ color: "#A63EDE" }}>
                    {currency(totalSummary?.totalCodUSD)}
                  </span>{" "}
                  USD
                </div>
              </div>
              <div style={{ color: "#12D56A" }}>
                ສ່ວນແບ່ງ COD:{" "}
                <span>
                  {currency(parseInt(totalSummary?.finalCODCommission))}
                </span>{" "}
                KIP
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
export default CommissionMobile;
